// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-request-demo-js": () => import("./../../../src/pages/request-demo.js" /* webpackChunkName: "component---src-pages-request-demo-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-competitors-js": () => import("./../../../src/templates/competitors.js" /* webpackChunkName: "component---src-templates-competitors-js" */),
  "component---src-templates-markdown-page-js": () => import("./../../../src/templates/markdown-page.js" /* webpackChunkName: "component---src-templates-markdown-page-js" */),
  "component---src-templates-model-js": () => import("./../../../src/templates/model.js" /* webpackChunkName: "component---src-templates-model-js" */),
  "component---src-templates-product-1-js": () => import("./../../../src/templates/product-1.js" /* webpackChunkName: "component---src-templates-product-1-js" */),
  "component---src-templates-solution-1-js": () => import("./../../../src/templates/solution-1.js" /* webpackChunkName: "component---src-templates-solution-1-js" */),
  "component---src-templates-solution-js": () => import("./../../../src/templates/solution.js" /* webpackChunkName: "component---src-templates-solution-js" */),
  "component---src-templates-use-case-js": () => import("./../../../src/templates/use-case.js" /* webpackChunkName: "component---src-templates-use-case-js" */)
}

